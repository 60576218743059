<template>
  <div class="row">
    <b-modal
      v-model="showModalDelete"
      :title="$t('gameservers.confirmation')"
      :ok-title="$t('gameservers.confirm')"
      :cancel-title="$t('gameservers.cancel')"
      @ok="deleteServer(ugcid)"
    >{{$t('gameservers.confirm_question')}}
    </b-modal>
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="form-group mb-2">
            <h4>{{machine_data.alias}}</h4>
          </div>
          <b-tabs
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted">
            <b-tab :title="$t('machines.ddos_protection')">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length">
                    <button type="button" class="btn btn-sm btn-primary mb-2 me-1" @click="addModal()"
                        v-if="!$store.state.session.subuser || ($store.state.session.custom_machines_permissions && $store.state.session.custom_machines_permissions[machine_id]['ddos_protection'])">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-primary mb-2 me-1" @click="getServers()">
                      <i class="fa fa-redo"></i>
                    </button>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter text-md-end">
                    <label class="d-inline-flex align-items-center fw-normal">
                      <span class="uil-search"></span>
                      <b-form-input
                        v-model="filter"
                        type="search"
                        :placeholder="$t('gameservers.search')"
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="form-select form-select-sm mx-2 w-50"
                      ></b-form-select>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive mb-0">
                <b-table
                  :fields="fields"
                  :items="serverList"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  @filtered="onFiltered"
                  sort-by="ip"
                >
                  <!-- Game ID column -->
                  <template v-slot:cell(id_game)="items">
                    <img class="img-game" :src="'/static/gameicons/' + items.item.id_game + '.png'">
                  </template>
                  <template v-slot:cell(ip)="items">
                    <p style="margin-bottom: 0">{{items.item.ip}}</p>
                  </template>
                  <template v-slot:cell(port)="items">
                    <p style="margin-bottom: 0" v-if="[4, 48].indexOf(items.item.id_game) !== -1">{{items.item.port_query}}</p>
                    <p style="margin-bottom: 0" v-else>{{items.item.port}}</p>
                  </template>
                  <!-- Server information column -->
                  <template v-slot:cell(info)="items">
                    <p style="margin-bottom: 0">
                      {{$t('gameservers.name')}}:
                      <template v-if="items.item.query && items.item.query.is_online">
                        {{items.item.query.name.substring(0, 50) + '...'}}
                      </template>
                    </p>
                    <p style="margin-bottom: 0">
                      {{$t('gameservers.players')}}:
                      <template v-if="items.item.query && items.item.query.is_online">
                        {{items.item.query.players.active}} из
                        {{items.item.query.players.total}}
                      </template>
                    </p>
                  </template>
                  <template v-slot:cell(control)="items">
                    <template v-if="!$store.state.session.subuser || ($store.state.session.custom_machines_permissions && $store.state.session.custom_machines_permissions[machine_id]['ddos_protection'])">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-primary"
                        @click="editModal(items.item)"
                        :title="$t('machines.edit')"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        @click="ugcid = items.item.id;showModalDelete = true;"
                        :title="$t('machines.delete')"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </template>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('machines.ddos_attacks')">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_length">
                    <button type="button" class="btn btn-sm btn-primary mb-2 me-1" @click="getDdosAttacks()">
                      <i class="fa fa-redo"></i>
                    </button>
                    <button type="button" class="btn btn-sm btn-primary mb-2 me-1" @click="clearDdosAttacks()">
                      {{$t('machines.clear')}}
                    </button>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter text-md-end">
                    <label class="d-inline-flex align-items-center fw-normal">
                      <span class="uil-search"></span>
                      <b-form-input
                        v-model="ddosAttacksFilter"
                        type="search"
                        :placeholder="$t('gameservers.search')"
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="form-select form-select-sm mx-2 w-50"
                      ></b-form-select>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive mb-0">
                <b-table
                  :fields="ddosAttacksFields"
                  :items="ddosAttacksList"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="ddosAttacksCurrentPage"
                  :filter="ddosAttacksFilter"
                  @filtered="onFilteredDdosAttacks"
                  sort-by="ip"
                >
                  <template v-slot:cell(importance)="items">
                    <i class="fa fa-star" style="color: gold"></i>
                    <template v-if="items.item.importance == 2">
                      <i class="fa fa-star" style="color: gold"></i>
                      <i class="fa fa-star" style="color: gold"></i>
                    </template>
                    <template v-else-if="items.item.importance == 1">
                      <i class="fa fa-star" style="color: gold"></i>
                      <i class="fa fa-star" style="color: grey"></i>
                    </template>
                    <template v-else>
                      <i class="fa fa-star" style="color: grey"></i>
                      <i class="fa fa-star" style="color: grey"></i>
                    </template>
                  </template>
                  <template v-slot:cell(stop)="items">
                    <template v-if="items.item.stop == '0000-00-00 00:00:00'">
                      {{$t('machines.attack_is_underway')}}
                    </template>
                    <template v-else>
                      {{items.item.stop}}
                    </template>
                  </template>
                  <template v-slot:cell(duration)="items">
                    {{(items.item.duration / 60).toFixed()}} {{$t('machines.minutes')}}
                  </template>
                  <template v-slot:cell(bps)="items">
                    {{(items.item.bps / 1048576).toFixed()}}
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded">
                      <!-- pagination -->
                      <b-pagination
                        v-model="ddosAttacksCurrentPage"
                        :total-rows="ddosAttacksTotalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal v-model="modal" :title="$t('machines.server')" hide-header-close ok-only>
      <div><label class="font-weight-bold">{{$t('machines.ports_range', { port_range_min: current_game.port_range_min, port_range_max: current_game.port_range_max })}}</label></div>
      <div><label class="font-weight-bold">{{$t('machines.rcon_port_range', { port_rcon_range_min: current_game.port_rcon_range_min, port_rcon_range_max: current_game.port_rcon_range_max })}}</label></div>
      <label class="mt-2 font-weight-bold" for="game">{{$t('machines.game')}}</label>
      <select id="game" v-model="game" class="form-control">
        <option
          v-for="game in gamesList"
          :key="game.gid"
          :value="game.gid"
        >{{game.name}}</option>
      </select>
      <label class="mt-2 font-weight-bold" for="ip">{{$t('machines.ip_address')}}</label>
      <select id="ip" v-model="ip" class="form-control">
        <option v-for="ip in machine_data.ip_list" :key="ip.ip" :value="ip.ip">{{ip.ip}}</option>
      </select>
      <label class="mt-2 font-weight-bold" for="port">{{$t('machines.port')}}</label>
      <b-form-input v-if="game == 6" readonly id="port" :placeholder="port_query > 0 ? (Number(port_query) + 1) : (Number(current_game.port_default) + 1)"/>
      <b-form-input v-else id="port" v-model="port" :placeholder="current_game.port_default"/>
      <template v-if="current_game.port_querydefault > 0">
        <label class="mt-2 font-weight-bold" for="query_port">Query {{$t('machines.port')}}</label>
        <b-form-input id="query_port" v-model="port_query" :placeholder="current_game.port_querydefault"/>
      </template>
      <template v-if="current_game.port_rcondefault > 0">
        <label class="mt-2 font-weight-bold" for="rcon_port"><template v-if="game == 63">Filetransfer</template><template v-else>Rcon</template> {{$t('machines.port')}}</label>
        <b-form-input id="rcon_port" v-model="port_rcon" :placeholder="current_game.port_rcondefault"/>
      </template>
      <template v-slot:modal-footer>
        <b-button v-if="edit" variant="primary" block @click="editServer()">
          {{$t('machines.edit')}}
        </b-button>
        <b-button v-else variant="primary" block @click="addServer()">
          {{$t('machines.add')}}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import axios from '@/modules/Axios'
am4core.useTheme(am4themes_animated)
am4core.options.autoDispose = true

export default {
  data () {
    return {
      machine_data: '',
      serverList: [],
      gamesList: [],
      modal: false,
      edit: false,
      ugcid: '',
      ip: 0,
      game: '',
      port: '',
      port_query: '',
      port_rcon: '',
      totalRows: 1,
      currentPage: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      showPassword: false,
      selectedCommand: undefined,
      charts: null,
      showModalReinstall: false,
      loading: false,
      osList: [],
      os: 0,
      ddosAttacksList: [],
      ddosAttacksTotalRows: 1,
      ddosAttacksCurrentPage: 1,
      ddosAttacksFilter: '',
      ipsTotalRows: 1,
      ipsCurrentPage: 1,
      ipsFilter: '',
      showModalDelete: false,
      modalControl: false,
      selectedAction: 'restart',
      librenmsGraph: 'port_bits',
      modalIps: false,
      editIp: [],
    }
  },
  computed: {
    machine_id () {
      if (typeof this.$route.params.upid !== 'undefined') {
        try {
          // Переводим в тип number
          return parseInt(this.$route.params.upid)
        } catch (ignored) {
          // В случае ошибке возвращаем undefined
          return undefined
        }
      }
      // Иначе возвращаем undefind
      return undefined
    },
    current_game () {
      let game = this.gamesList.find(item => item.gid == this.game)
      if (!game) {
        return {
          gid: 0,
          name: '',
          port_range_min: 0,
          port_range_max: 65535,
          port_rcon_range_min: 0,
          port_rcon_range_max: 65535,
          port_default: 0,
          port_querydefault: 0,
          port_rcondefault: 0
        }
      }
      return game
    },
    /**
     * Поля таблицы списка серверов
     */
    fields () {
      return [
        {
          key: 'id_game',
          label: this.$t('gameservers.game'),
          sortable: true,
          thStyle: {
            width: '70px',
          },
        },
        {
          key: 'ip',
          label: this.$t('gameservers.address'),
          sortable: true,
          thStyle: {
            width: '150px',
          }
        },
        {
          key: 'port',
          label: this.$t('gameservers.port'),
          sortable: true,
          thStyle: {
            width: '70px',
          }
        },
        {
          key: 'info',
          label: this.$t('gameservers.info'),
        },
        {
          key: 'control',
          label: this.$t('gameservers.control'),
          thStyle: {
            width: '20%',
          },
        }
      ]
    },
    ddosAttacksFields () {
      return [
        {
          key: 'ip',
          label: 'IP',
          sortable: true,
        },
        {
          key: 'importance',
          label: this.$t('machines.threat_level'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$t('machines.attack_type'),
          sortable: true,
        },
        {
          key: 'start',
          label: this.$t('machines.attack_start'),
          sortable: true,
        },
        {
          key: 'stop',
          label: this.$t('machines.attack_stop'),
          sortable: true,
        },
        {
          key: 'duration',
          label: this.$t('machines.duration'),
          sortable: true,
        },
        {
          key: 'bps',
          label: this.$t('machines.mbps'),
          sortable: true,
        },
        {
          key: 'pps',
          label: this.$t('machines.pps'),
          sortable: true,
        }
      ]
    },
    perPage: {
      get: function () {
        return this.$store.state.layout.perPage
      },
      set: function (newValue) {
        this.$store.commit('layout/CHANGE_PER_PAGE', newValue)
      }
    }
  },
  notifications: {notify: {}},
  methods: {
    async getMachineData () {
      try {
        // Отправка запроса на сервер
        const response = await axios.post('Userproducts.php', {
          mode: 'get_userproduct',
          upid: this.machine_id
        })

        this.machine_data = response.data
        this.ipsTotalRows = this.machine_data.ip_list.length;
      } catch (error) {
        console.error(error.message)
        this.$router.push(`.`)
      }
    },
    async getServers () {
      // Отправляем запрос на сервер
      try {
        // Отправляем запрос на сервер
        const response = await axios.post('Userproducts.php', {
          mode: 'list_servers',
          upid: this.machine_id
        })

        // Если ответ пуст - завершаем выполнение метода
        if (typeof response.data === 'undefined' || !response.data) return

        this.serverList = response.data
        this.totalRows = this.serverList.length;
      } catch (error) {
        console.error(error.message)
      }
      this.getGameServersQueryStatus()
    },
    async getGames () {
      // Отправляем запрос на сервер
      try {
        // Отправляем запрос на сервер
        const response = await axios.post('Games.php')

        // Если ответ пуст - завершаем выполнение метода
        if (typeof response.data === 'undefined' || !response.data) return

        this.gamesList = response.data
        if (this.gamesList.length !== 0) { this.game = this.gamesList[0].gid }
      } catch (error) {
        console.error(error.message)
      }
    },
    async getDdosAttacks () {
      // Отправляем запрос на сервер
      try {
        // Отправляем запрос на сервер
        const response = await axios.post('Userproducts.php', {
          mode: 'list_ddos_attacks',
          upid: this.machine_id
        })

        // Если ответ пуст - завершаем выполнение метода
        if (typeof response.data === 'undefined' || !response.data) return

        this.ddosAttacksList = response.data
        this.ddosAttacksTotalRows = this.ddosAttacksList.length;
      } catch (error) {
        console.error(error.message)
      }
    },
    async clearDdosAttacks () {
      try {
        const response = await axios.post('Userproducts.php', {
          mode: 'clear_ddos_attacks',
          upid: this.machine_id
        })
        this.notify({
          title: this.$t('machines.title_message_control'),
          type: 'success',
          message: this.$t(response.data)
        })
        this.getDdosAttacks()
      } catch (error) {
        this.notify({
          title: this.$t('machines.title_message_control'),
          type: 'error',
          message: error.response ? this.$t(error.response.data) : error.message
        })
      }
    },
    getGameServersQueryStatus () {
      // Количество игровых серверов
      let serverCount = this.serverList.length - 1
      for (; serverCount !== -1; serverCount--) {
        axios
          .post('Userproducts.php', {
            mode: 'query_server',
            upid: this.machine_id,
            ugcid: this.serverList[serverCount].id
          })
          .then(response => {
            let server = response.data
            let length = this.serverList.length - 1
            for (; length !== -1; length--) {
              if (this.serverList[length].id === server.id) {
                this.$set(this.serverList[length], 'query', server)
              }
            }
          })
          .catch(error => {
            this.notify({
              type: 'error',
              title: this.$t('gameservers.title_message_query', { ugcid: error.response.config.data.split('=').filter(function (item, i, mas) { if (i == mas.length - 1) return true }) }),
              message: error.response ? this.$t(error.response.data) : error.message
            })
          })
      }
    },
    addModal () {
      this.edit = false
      if (this.machine_data.ip_list.length !== 0) { this.ip = this.machine_data.ip_list[0].ip }
      if (this.gamesList.length !== 0) { this.game = this.gamesList[0].gid }
      this.port = ''
      this.port_query = ''
      this.port_rcon = ''
      this.modal = !this.modal
    },
    editModal (item) {
      this.edit = true
      this.ugcid = item.id
      this.ip = item.ip
      this.game = item.id_game
      this.port = item.port
      this.port_query = item.port_query
      this.port_rcon = item.port_rcon
      this.modal = !this.modal
    },
    async deleteServer (ugcid) {
      try {
        const response = await axios.post('Userproducts.php', {
          mode: 'delete_server',
          upid: this.machine_id,
          ugcid: ugcid
        })
        this.getServers()
      } catch (error) {
        this.notify({
          type: 'error',
          message: error.response ? this.$t(error.response.data) : error.message
        })
      }
    },
    async addServer () {
      try {
        const response = await axios.post('Userproducts.php', {
          mode: 'add_server',
          upid: this.machine_id,
          ip: this.ip,
          gid: this.game,
          port: this.port,
          port_query: (this.current_game.port_querydefault > 0) ? this.port_query : 0,
          port_rcon: (this.current_game.port_rcondefault > 0) ? this.port_rcon : 0
        })
        this.modal = false
        this.getServers()
      } catch (error) {
        this.notify({
          type: 'error',
          message: error.response ? this.$t(error.response.data) : error.message
        })
      }
    },
    async editServer () {
      try {
        const response = await axios.post('Userproducts.php', {
          mode: 'edit_server',
          upid: this.machine_id,
          ugcid: this.ugcid,
          ip: this.ip,
          gid: this.game,
          port: this.port,
          port_query: (this.current_game.port_querydefault > 0) ? this.port_query : 0,
          port_rcon: (this.current_game.port_rcondefault > 0) ? this.port_rcon : 0
        })
        this.modal = false
        this.getServers()
      } catch (error) {
        this.notify({
          type: 'error',
          message: error.response ? this.$t(error.response.data) : error.message
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredDdosAttacks(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.ddosAttacksTotalRows = filteredItems.length;
      this.ddosAttacksCurrentPage = 1;
    },
    onFilteredIps(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.ipsTotalRows = filteredItems.length;
      this.ipsCurrentPage = 1;
    },
  },
  mounted () {
    this.getMachineData()
    this.getGames()
    this.getServers()
    this.getDdosAttacks()
  }
}
</script>
<style scoped>
  img.img-game {
    width: 40px;
    height: 40px;
  }
</style>
